import { LocaleID } from '@common/clients/api';

export enum IsoLocale {
    bg_BG = 'bg-BG',
    cs_CZ = 'cs-CZ',
    da_DK = 'da-DK',
    de_DE = 'de-DE',
    el_GR = 'el-GR',
    en_GB = 'en-GB',
    es_ES = 'es-ES',
    et_EE = 'et-EE',
    fi_FI = 'fi-FI',
    fr_FR = 'fr-FR',
    hu_HU = 'hu-HU',
    id_ID = 'id-ID',
    it_IT = 'it-IT',
    ja_JP = 'ja-JP',
    lt_LT = 'lt-LT',
    lv_LV = 'lv-LV',
    nl_BE = 'nl-BE',
    nl_NL = 'nl-NL',
    pl_PL = 'pl-PL',
    pt_BR = 'pt-BR',
    pt_PT = 'pt-PT',
    ro_RO = 'ro-RO',
    ru_RU = 'ru-RU',
    sk_SK = 'sk-SK',
    sl_SI = 'sl-SI',
    sv_SE = 'sv-SE',
    tr_TR = 'tr-TR',
    zh_CN = 'zh-CN',
}

export const LocaleToIsoDictionary: Record<LocaleID, IsoLocale> = {
    bg: IsoLocale.bg_BG,
    cs: IsoLocale.cs_CZ,
    da: IsoLocale.da_DK,
    de: IsoLocale.de_DE,
    el: IsoLocale.el_GR,
    en: IsoLocale.en_GB,
    es: IsoLocale.es_ES,
    es_ES: IsoLocale.es_ES,
    et: IsoLocale.et_EE,
    fi: IsoLocale.fi_FI,
    fr: IsoLocale.fr_FR,
    hu: IsoLocale.hu_HU,
    id: IsoLocale.id_ID,
    it: IsoLocale.it_IT,
    ja: IsoLocale.ja_JP,
    lt: IsoLocale.lt_LT,
    lv: IsoLocale.lv_LV,
    nl_BE: IsoLocale.nl_BE,
    nl_NL: IsoLocale.nl_NL,
    pl: IsoLocale.pl_PL,
    pt_BR: IsoLocale.pt_BR,
    pt_PT: IsoLocale.pt_PT,
    ro: IsoLocale.ro_RO,
    ru: IsoLocale.ru_RU,
    sk: IsoLocale.sk_SK,
    sl: IsoLocale.sl_SI,
    sv: IsoLocale.sv_SE,
    tr: IsoLocale.tr_TR,
    zh_CN: IsoLocale.zh_CN,
};

export const getIsoForLocale = (locale: LocaleID = LocaleID.NL_NL): IsoLocale =>
    LocaleToIsoDictionary[locale];
